import React, { useState } from 'react';
import '../../../styles/components/portal/sidemenu/portal-sidemenu-item.scss';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

const PortalSideMenuItem = (props) => {

    const [expanded, setExpanded] = useState(false);

    return (
        <div className='portal-sidemenu-item-container'>
            <div className='portal-sidemenu-item' onClick={() => {
                if (props.children === null || props.children === undefined) {
                    props.onItemClick(props.itemIndex)
                } else {
                    setExpanded(!expanded);
                }
            }}>
                <div className='portal-sidemenu-item-icon'>
                    {props.icon}
                </div>
                <div className='portal-sidemenu-item-name'>{props.name}</div>
                {props.children && <div className='portal-sidemenu-expand-icon'>
                    {expanded ? <MdExpandLess size={25}/> : <MdExpandMore size={25}/>}
                </div>}
            </div>
            {expanded && props.children && <div className='portal-sidemenu-item-children'>
                {
                    props.children.map((child, index) => {
                        const selected = props.itemIndex === props.selectedParentIndex && props.selectedChildIndex === index;

                        return (
                            <div
                                key={index}
                                className={`portal-sidemenu-item${selected ? ' selected': ''} expanded-child`}
                                onClick={() => {
                                    props.onItemClick(props.itemIndex, index)
                                }}
                            >
                                <div className='portal-sidemenu-item-icon'>
                                    {child.icon}
                                </div>
                                <div className='portal-sidemenu-item-name'>{child.name}</div>
                            </div>
                        )
                    })
                }
            </div>}
        </div>
    )
}

export default PortalSideMenuItem;