import React, { useEffect, useState } from 'react';
import itemsService from '../../../../services/items.js';
import '../../../../styles/components/pages/portal/general/items-list.scss';
import LoadingIndicator from '../../../indicator/LoadingIndicator';
import SearchBar from '../../../inputs/SearchBar.js';
import itemGroupsService from '../../../../services/itemGroups.js';

const ItemsList = () => {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [itemGroups, setItemGroups] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        const loadItems = async () => {
            setLoading(true);

            try {
                const response = await itemsService.getItems();

                setItems(response.data);
            } catch (error) {
                console.error('Error loading items:', error);
            }

            try {
                const response = await itemGroupsService.getItemGroups();

                setItemGroups(response.data);
            } catch (error) {
                console.error('Error loading item groups:', error);
            }

            setLoading(false);
        }

        loadItems();
    }, []);

    if (loading) {
        return (
            <div className='items-loading'>
                <LoadingIndicator
                    label={'Loading Items'}
                />
            </div>
        )
    }

    const getItemGroupName = (groupId) => {
        const itemGroup = itemGroups.find((group) => group.id === groupId);

        return itemGroup ? itemGroup.description : 'Unknown';
    }

    const filteredItems = items.filter((item) => {
        const lowercaseSearchKeyword = searchKeyword.toLowerCase();
        return item.id.toString().includes(lowercaseSearchKeyword) || item.item.toLowerCase().includes(lowercaseSearchKeyword) || item.label.toLowerCase().includes(lowercaseSearchKeyword);
    });

    return (
        <div className='items-list'>
            <div className='items-filtering'>
                <div className='items-filtering-search'>
                    <SearchBar
                        placeholder={'Search Items'}
                        value={searchKeyword}
                        onChange={(setSearchKeyword)}
                    />
                </div>
            </div>
            <div className='items-table'>
                <div className='items-table-header'>
                    <div className='items-table-header-id'>ID</div>
                    <div className='items-table-header-item'>Item</div>
                    <div className='items-table-header-label'>Label</div>
                    <div className='items-table-header-limit'>Limit</div>
                    <div className='items-table-header-weight'>Weight</div>
                    <div className='items-table-header-group'>Group</div>
                </div>
                <div className='items-table-content'>
                    {filteredItems.map((item, index) => {
                        return (
                            <div key={index} className='items-table-row'>
                                <div className='items-table-row-id'>{item.id}</div>
                                <div className='items-table-row-item'>{item.item}</div>
                                <div className='items-table-row-label'>{item.label}</div>
                                <div className='items-table-row-limit'>{item.limit}</div>
                                <div className='items-table-row-weight'>{item.weight}</div>
                                <div className='items-table-row-group'>{getItemGroupName(item.groupId)}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default ItemsList;