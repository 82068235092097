import toast from 'react-hot-toast';

export const toasterSettings = {
    position: 'bottom-right'
}

export function successfulToast(message) {
    toast.success(message, {
        style: {
            background: '#000',
            zIndex: 10000
        }
    });
}

export function errorToast(message) {
    toast.error(message, {
        style: {
            background: '#000',
            zIndex: 10000
        }
    });
}