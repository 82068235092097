import React from 'react';

const Rules = () => {
    return (
        <div className='rules'>
            Rules
        </div>
    )
}

export default Rules;