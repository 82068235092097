import React from 'react';
import '../../styles/components/header/portal-header.scss';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';

const PortalHeader = (props) => {
    const navigate = useNavigate();

    const { userDetails, userRoles, setPageName } = props;

    const renderRoleCard = (roleName, roleClass, role) => {
        if (userRoles.includes(role)) {
            return (
                <div className={`portal-header-role ${roleClass}`}>{roleName}</div>
            );
        }
    }

    return (
        <div className='portal-header'>
            <div className='portal-header-left'>
                <div 
                    className='portal-header-back'
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <MdArrowBack/>
                    <div className='portal-header-back-text'>Back to website</div>
                </div>
            </div>
            <div className='portal-header-center'>
                {renderRoleCard('Staff Member', 'role-staff', 'Staff')}
                {renderRoleCard('Developer', 'role-developer', 'Development Team')}
                {renderRoleCard('Management', 'role-management', 'Management')}
            </div>
            <div className='portal-header-right'>
                <div className='portal-header-user'>
                    <img className='portal-header-avatar' src={`https://cdn.discordapp.com/avatars/${userDetails.id}/${userDetails.avatar}.png`}/>
                    <div className='portal-header-username'>{userDetails.username}</div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.user.details,
        userRoles: state.user.roles
    }
}

export default connect(mapStateToProps)(PortalHeader);