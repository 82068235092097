import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import whitelist from '../../../../services/whitelist';
import { getDisplayStatus } from '../../../../helpers/app-status';
import '../../../../styles/components/pages/portal/general/whitelist-apps-view.scss';
import moment from 'moment';
import LoadingIndicator from '../../../indicator/LoadingIndicator';
import { connect } from 'react-redux';
import { errorToast, successfulToast } from '../../../../helpers/toast-helper';

const WhitelistAppsView = (props) => {

    const { userDetails } = props;

    const [loading, setLoading] = useState(false);
    const [application, setApplication] = useState({});
    const [adminResponse, setAdminResponse] = useState('');

    const params = useParams();
    const { appId } = params;

    const getApplicationById = async () => {
        setLoading(true);

        try {
            const response = await whitelist.getWhitelistApplicationByAppId(appId);
            setApplication(response.data);
        } catch (error) {
            console.error('Error loading whitelist app:', error);
        }

        setLoading(false);
    }

    useEffect(() => {
        const loadApplication = async () => {
            await getApplicationById();
        }

        loadApplication();
    }, []);

    if (loading) {
        return (
            <div className='whitelist-apps-view-loading'>
                <LoadingIndicator
                    label={'Loading Application'}
                />
            </div>
        )
    }

    const getAgeFromDateOfBirth = (dateOfBirth) => {
        return moment().diff(moment(dateOfBirth), 'years');
    };

    const renderDateOfBirthContainer = () => {

        const age = getAgeFromDateOfBirth(application.date_of_birth);

        return (
            <div className='whitelist-apps-view-content-row'>
                <div className='whitelist-apps-view-content-row-label'>Date of Birth</div>
                <div className='whitelist-apps-view-content-row-container'>
                    <div className='whitelist-apps-view-content-row-value'>{`${moment(application.date_of_birth).format('LL')} (${age})`}</div>
                    {age < 18 && <div className='whitelist-apps-view-content-row-error'>{'UNDERAGE'}</div>}
                </div>
            </div>
        )
    }

    const renderClaimButton = () => {
        return (
            <div className='whitelist-apps-view-admin-claim' onClick={async () => {
                try {
                    await whitelist.claimWhitelistApplication(application.whitelist_app_id, userDetails);
                    successfulToast('Successfully claimed app');
                } catch {
                    errorToast('Error claiming app');
                }

                await getApplicationById();
                
            }}>Claim</div>
        );
    }

    const renderClaimedDetails = () => {
        if (application.status_changed_by_id === userDetails.id) {
            return (
                <div className='whitelist-apps-view-admin-claimdetails'>
                    <div className='whitelist-apps-view-admin-claimdetails-buttons'>
                        <div className='whitelist-apps-view-admin-claimdetails-button approve' onClick={async () => {
                            try {
                                const approvalBody = {
                                    userDetails,
                                    adminResponse
                                };

                                await whitelist.approveWhitelistApplication(application.whitelist_app_id, approvalBody);

                                successfulToast('Successfully approved app');
                            } catch {
                                errorToast('Error approving app');
                            }

                            await getApplicationById();
                        }}>
                            Approve
                        </div>
                        <div className='whitelist-apps-view-admin-claimdetails-button deny' onClick={async () => {

                            if (!adminResponse || adminResponse === '') {
                                errorToast('Please provide a reason for denial');
                                return;
                            }

                            try {
                                const denialBody = {
                                    userDetails,
                                    adminResponse
                                };

                                await whitelist.denyWhitelistApplication(application.whitelist_app_id, denialBody);

                                successfulToast('Successfully denied app');
                            } catch {
                                errorToast('Error denying app');
                            }

                            await getApplicationById();
                        }}>
                            Deny
                        </div>
                        <div className='whitelist-apps-view-admin-claimdetails-button unclaim' onClick={async () => {
                            try {
                                await whitelist.unclaimWhitelistApplication(application.whitelist_app_id);
                                successfulToast('Successfully unclaimed app');
                            } catch {
                                errorToast('Error unclaiming app');
                            }

                            await getApplicationById();
                        }}>
                            Unclaim
                        </div>
                    </div>
                    <div className='whitelist-apps-view-admin-claimdetails-response'>
                        <textarea className='whitelist-apps-view-admin-claimdetails-response-input' onChange={(e) => setAdminResponse(e.target.value)} value={adminResponse}/>
                    </div>
                </div>
            )
        }

        return (
            <div className='whitelist-apps-view-admin-details'>
                {`Claimed by ${application.status_changed_by_name} (${moment(application.status_changed_date).format('LLL')})`}
            </div>
        )
    }

    const renderApprovedDetails = () => {
        return (
            <div className='whitelist-apps-view-admin-details'>
                <div className='whitelist-apps-view-admin-details-title'>{`Approved by ${application.status_changed_by_name} (${moment(application.status_changed_date).format('LLL')})`}</div>
                {application.status_changed_info && <div className='whitelist-apps-view-admin-details-response'>{`"${application.status_changed_info}"`}</div>}
            </div>
        )
    };

    const renderDeniedDetails = () => {
        return (
            <div className='whitelist-apps-view-admin-details'>
                <div className='whitelist-apps-view-admin-details-title'>{`Denied by ${application.status_changed_by_name} (${moment(application.status_changed_date).format('LLL')})`}</div>
                {application.status_changed_info && <div className='whitelist-apps-view-admin-details-response'>{`"${application.status_changed_info}"`}</div>}
            </div>
        )
    }

    return (
        <div className='whitelist-apps-view'>
            <div className='whitelist-apps-view-header'>
                <div className='whitelist-apps-view-header-title'>
                    {`Whitelist Application - ${application.discord_username}`}
                </div>
                {application.status && <div className={`whitelist-apps-view-header-status ${application.status}`}>
                    {getDisplayStatus(application.status)}
                </div>}
            </div>
            <div className='whitelist-apps-view-admin'>
                {application.status === 'pending' && renderClaimButton()}
                {application.status === 'review' && renderClaimedDetails()}
                {application.status === 'approved' && renderApprovedDetails()}
                {application.status === 'denied' && renderDeniedDetails()}
            </div>
            <div className='whitelist-apps-view-content'>
                {renderDateOfBirthContainer()}
                <div className='whitelist-apps-view-content-row'>
                    <div className='whitelist-apps-view-content-row-label'>{'Do you have any previous roleplay experience?'}</div>
                    <div className='whitelist-apps-view-content-row-value'>{application.previous_experience}</div>
                </div>
                <div className='whitelist-apps-view-content-row'>
                    <div className='whitelist-apps-view-content-row-label'>{'Why are you interested in playing at Legendary Roleplay?'}</div>
                    <div className='whitelist-apps-view-content-row-value'>{application.why_interested}</div>
                </div>
                <div className='whitelist-apps-view-content-row'>
                    <div className='whitelist-apps-view-content-row-label'>{'What story have you created to play at Legendary? Go into detail on your character’s name, backstory, and possible arc (Law, Criminal, DOJ, Civilian)'}</div>
                    <div className='whitelist-apps-view-content-row-value'>{application.story}</div>
                </div>
                <div className='whitelist-apps-view-content-row'>
                    <div className='whitelist-apps-view-content-row-label'>{'In your own words define RDM (Random Death Match)'}</div>
                    <div className='whitelist-apps-view-content-row-value'>{application.rdm}</div>
                </div>
                <div className='whitelist-apps-view-content-row'>
                    <div className='whitelist-apps-view-content-row-label'>{'In your own words define powergaming'}</div>
                    <div className='whitelist-apps-view-content-row-value'>{application.powergaming}</div>
                </div>
                <div className='whitelist-apps-view-content-row'>
                    <div className='whitelist-apps-view-content-row-label'>{'In your own words define what OOC Bleeding is'}</div>
                    <div className='whitelist-apps-view-content-row-value'>{application.ooc_bleeding}</div>
                </div>
                <div className='whitelist-apps-view-content-row'>
                    <div className='whitelist-apps-view-content-row-label'>{'In your own words define what Roleplay over Gunplay means'}</div>
                    <div className='whitelist-apps-view-content-row-value'>{application.roleplay_over_gunplay}</div>
                </div>
                <div className='whitelist-apps-view-content-row'>
                    <div className='whitelist-apps-view-content-row-label'>{'You\'ve been ambushed by the law during a poker game and ended up hogtied. One of your gang members knocks the lawman unconscious but runs off saving themselves and leaving you behind. After two minutes, you hear voices; it\'s the local lawmen searching for you and your gang. If they find you, you\'ll face serious charges. If you break free, you could escape capture. What are you able to do in this situation according to our rules?'}</div>
                    <div className='whitelist-apps-view-content-row-value'>{application.situational}</div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.user.details,
    }
};

export default connect(mapStateToProps)(WhitelistAppsView);