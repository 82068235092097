import axios from 'axios';

export default {
    addWhitelistApplication: async (whitelist) => {
        return await axios.post('/whitelist', whitelist);
    },
    getWhitelistApplications: async () => {
        return await axios.get('/whitelist');
    },
    getWhitelistApplicationsByDiscordId: async (discordId) => {
        return await axios.get(`/whitelist/byDiscordId/${discordId}`);
    },
    getWhitelistApplicationByAppId: async (appId) => {
        return await axios.get(`/whitelist/byAppId/${appId}`);
    },
    claimWhitelistApplication: async (appId, userDetails) => {
        return await axios.post(`/whitelist/claim/${appId}`, userDetails);
    },
    unclaimWhitelistApplication: async (appId) => {
        return await axios.get(`/whitelist/unclaim/${appId}`);
    },
    approveWhitelistApplication: async (appId, approvalBody) => {
        return await axios.post(`/whitelist/approve/${appId}`, approvalBody);
    },
    denyWhitelistApplication: async (appId, denialBody) => {
        return await axios.post(`/whitelist/deny/${appId}`, denialBody);
    },
}