import { getDiscordRoles } from "../helpers/roles-helper";
import whitelist from '../services/whitelist';

export const mountPoint = 'user';

export const actions = {
    SET_USER_LOADING: `${mountPoint}/SET_USER_LOADING`,
    SET_USER: `${mountPoint}/SET_USER`,
    LOGOUT_USER: `${mountPoint}/LOGOUT_USER`,
}

const initialState = {
    details: {},
    roles: [],
    applications: {},
    isLoading: false,
    isLoggedIn: false
}

export const getUserDetails = () => {
    return async dispatch => {
        dispatch({
            type: actions.SET_USER_LOADING
        })

        try {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));

            if (userDetails) {
                const response = await fetch(`https://discordapp.com/api/users/@me/guilds/1139609447480688733/member`, {
                    headers: {
                        authorization: `Bearer ${userDetails.token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to get user details');
                }

                const discordResponseJson = await response.json();

                dispatch({
                    type: actions.SET_USER,
                    details: userDetails,
                    roles: getDiscordRoles(discordResponseJson.roles),
                    loggedIn: true
                });
            } else {
                dispatch({
                    type: actions.SET_USER,
                    details: {},
                    roles: [],
                    loggedIn: false
                });
            }
        } catch (error) {
            dispatch({
                type: actions.SET_USER,
                details: {},
                roles: [],
                loggedIn: false
            });
        }
    }
}

export const setUserDetails = (discordResponse) => {
    return async dispatch => {
        dispatch({
            type: actions.SET_USER_LOADING
        });

        try {
            const response = await fetch(`https://discordapp.com/api/users/@me/guilds/1139609447480688733/member`, {
                headers: {
                    authorization: `${discordResponse.token_type} ${discordResponse.access_token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to get user details');
            }

            const discordResponseJson = await response.json();

            const userDetails = {
                avatar: discordResponse.user.avatar,
                id: discordResponse.user.id,
                global_name: discordResponse.user.global_name,
                username: discordResponse.user.username,
                token: discordResponse.access_token,
            };

            localStorage.setItem('userDetails', JSON.stringify(userDetails));

            dispatch({
                type: actions.SET_USER,
                details: userDetails,
                roles: getDiscordRoles(discordResponseJson.roles),
                loggedIn: true
            });
        } catch (error) {
            dispatch({
                type: actions.SET_USER,
                details: {},
                roles: [],
                loggedIn: false
            });
        }
    }
}

export const logoutUser = () => {
    return dispatch => {
        dispatch({
            type: actions.LOGOUT_USER
        });

        localStorage.removeItem('userDetails');
    }
}

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_USER:
            return {
                ...state,
                details: action.details,
                roles: action.roles,
                isLoading: false,
                isLoggedIn: action.loggedIn
            }
        case actions.SET_USER_LOADING:
            return {
                ...state,
                details: {},
                roles: [],
                isLoading: true,
                isLoggedIn: false
            }
        case actions.LOGOUT_USER:
            return {
                ...state,
                details: {},
                roles: [],
                isLoading: false,
                isLoggedIn: false
            }
        default:
            return state;
    }
}