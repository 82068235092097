import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import * as userDux from '../dux/user-dux';

export const store = configureStore({
    reducer: {
        [userDux.mountPoint]: userDux.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        }),
});