import React from 'react';

const ManageBusinesses = () => {
    return (
        <div className='manage-businesses'>
            Manage Businesses
        </div>
    )
}

export default ManageBusinesses;