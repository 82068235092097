import React, { useEffect, useState } from 'react';
import whitelist from '../../../../services/whitelist';
import LoadingIndicator from '../../../indicator/LoadingIndicator';
import '../../../../styles/components/pages/portal/general/whitelist-apps.scss';
import { getLocalDateTimeString } from '../../../../helpers/date-format';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { getDisplayStatus } from '../../../../helpers/app-status';

const WhitelistApps = () => {

    const [loading, setLoading] = useState(false);
    const [whitelistApps, setWhitelistApps] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const loadWhitelistApps = async () => {
            setLoading(true);

            try {
                const response = await whitelist.getWhitelistApplications();

                setWhitelistApps(response.data);
            } catch (error) {
                console.error('Error loading whitelist apps:', error);
            }

            setLoading(false);
        }

        loadWhitelistApps();
    }, []);

    if (loading) {
        return (
            <div className='whitelist-apps-loading'>
                <LoadingIndicator
                    label={'Loading Applications'}
                />
            </div>
        )
    }

    return (
        <div className='whitelist-apps'>
            <div className='whitelist-apps-filtering'></div>
            <div className='whitelist-apps-table'>
                <div className='whitelist-apps-table-header'>
                    <div className='whitelist-apps-header-actions'>Actions</div>
                    <div className='whitelist-apps-header-name'>Name</div>
                    <div className='whitelist-apps-header-status'>Status</div>
                    <div className='whitelist-apps-header-date'>Submitted Date</div>
                    <div className='whitelist-apps-header-last-person'>Last Changed By</div>
                    <div className='whitelist-apps-header-last-date'>Last Changed Date</div>
                </div>
                <div className='whitelist-apps-table-content'>
                    {whitelistApps.map((app, index) => {
                        return (
                            <div className='whitelist-apps-table-row' key={index}>
                                <div className='whitelist-apps-row-actions'>
                                    <div 
                                        className='whitelist-apps-row-claim'
                                        onClick={() => navigate(`/staff/whitelist/${app.whitelist_app_id}`)}
                                    >
                                        View App
                                    </div>
                                </div>
                                <div className='whitelist-apps-row-name'>{app.discord_username}</div>
                                <div className='whitelist-apps-row-status'>
                                    <div className={`whitelist-apps-row-status-text ${app.status}`}>{getDisplayStatus(app.status)}</div>
                                </div>
                                <div className='whitelist-apps-row-date'>{getLocalDateTimeString(app.created_date)}</div>
                                <div className='whitelist-apps-row-last-person'>{app.status_changed_by_name || 'N/A'}</div>
                                <div className='whitelist-apps-row-last-date'>{app.status_changed_date ? moment(app.status_changed_date).format('LLL') : 'N/A'}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default WhitelistApps;