import React, { useEffect, useState } from 'react';
import PortalSideMenuItem from './PortalSideMenuItem';
import { MdApproval, MdBusiness, MdComputer, MdDashboard, MdList, MdListAlt, MdPeople } from 'react-icons/md';
import '../../../styles/components/portal/sidemenu/portal-sidemenu.scss';
import { useLocation, useNavigate } from 'react-router';

function getSideMenuItems() {
    return [
        {
            icon: <MdDashboard/>,
            name: 'Dashboard',
            path: '/staff'
        },
        {
            icon: <MdPeople/>,
            name: 'Staff Roster',
            path: '/staff/roster'
        },
        {
            icon: <MdList/>,
            name: 'Items List',
            path: '/staff/items'
        },
        {
            icon: <MdApproval/>,
            name: 'Whitelist Apps',
            path: '/staff/whitelist'
        },
        {
            icon: <MdComputer/>,
            name: 'Development',
            path: '/staff/development',
            children: [
                {
                    icon: <MdListAlt/>,
                    name: 'Recipe Generator',
                    path: '/staff/development/recipes'
                }
            ]
        },
        {
            icon: <MdBusiness/>,
            name: 'Business Team',
            path: '/staff/business',
            children: [
                {
                    icon: <MdBusiness/>,
                    name: 'Manage Businesses',
                    path: '/staff/business/list',
                }
            ]
        }
    ]
}

const PortalSideMenu = (props) => {

    const menuItems = getSideMenuItems();
    const navigate = useNavigate();
    const location = useLocation();

    const selectedItem = menuItems.find(mi => mi.path.includes(location.pathname));
    const selectedIndex = menuItems.indexOf(selectedItem);

    let childIndex = -1;
    let parentIndex = -1;

    const [selectedItemIndex, setSelectedItem] = useState(selectedIndex);
    const [selectedParentIndex, setSelectedParentIndex] = useState(parentIndex);
    const [selectedChildIndex, setSelectedChildIndex] = useState(childIndex);

    if (selectedIndex === -1) {
        const selectedItemWithChild = menuItems.find(mi => mi.children && mi.children.some(c => c.path.includes(location.pathname)));
        parentIndex = menuItems.indexOf(selectedItemWithChild);

        if (selectedItemWithChild) {
            const selectedChildItem = selectedItemWithChild.children.find(ci => ci.path.includes(location.pathname));
            childIndex = selectedItemWithChild.children.indexOf(selectedChildItem);
        }
    }

    useEffect(() => {
        const selectedItem = menuItems.find(mi => mi.path === location.pathname);
        const selectedIndex = menuItems.indexOf(selectedItem);

        setSelectedItem(selectedIndex);
    }, [location.pathname])

    const onItemClick = (selectedItemIndex, selectedChildIndex) => {
        navigate(menuItems[selectedItemIndex].path);

        if (selectedChildIndex != null) {
            setSelectedParentIndex(selectedItemIndex);
            setSelectedChildIndex(selectedChildIndex);
            navigate(menuItems[selectedItemIndex].children[selectedChildIndex].path);
        } else {
            setSelectedParentIndex(-1);
            setSelectedChildIndex(-1);
            setSelectedItem(selectedItemIndex);
            navigate(menuItems[selectedItemIndex].path);
        }
    }

    return (
        <div className='portal-sidemenu'>
            {menuItems.map((item, index) => {
                return (
                    <PortalSideMenuItem
                        key={index}
                        itemIndex={index}
                        onItemClick={onItemClick}
                        icon={item.icon}
                        name={item.name}
                        path={item.path}
                        children={item.children}
                        selected={selectedItemIndex === index}
                        selectedParentIndex={selectedParentIndex}
                        selectedChildIndex={selectedChildIndex}
                    />
                )
            })}
        </div>
    )
}

export default PortalSideMenu;