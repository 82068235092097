import React, { useEffect, useState } from 'react';
import '../../styles/components/pages/applications.scss';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import { render } from '@testing-library/react';
import moment from 'moment';
import whitelist from '../../services/whitelist';
import LoadingIndicator from '../indicator/LoadingIndicator';

const Applications = (props) => {
    const { userLoggedIn, userDetails, userRoles } = props;

    const [loading, setLoading] = useState(false);
    const [whitelistApp, setWhitelistApp] = useState({});

    useEffect(() => {
        const getApplication = async () => {
            setLoading(true);

            if (userDetails) {
                try {
                    const response = await whitelist.getWhitelistApplicationsByDiscordId(userDetails.id);
                    
                    if (response.data.length > 0) {
                        setWhitelistApp(response.data[0]);
                    }
                } catch (error) {
                    console.error('Error loading whitelist apps:', error);
                }
            }

            setLoading(false);
        }

        getApplication();
    }, [])

    if (!userLoggedIn) {
        return (
            <div className='applications'>
                <Application 
                    title={'Login Required'}
                    description={'You must be logged in to view available applications!'}
                />
            </div>
        )
    }

    if (loading) {
        return (
            <LoadingIndicator label={'Loading Applications'}/>
        )
    };

    const getWhitelistStatusMessage = () => {

        if (!whitelistApp) {
            return null;
        }

        if (whitelistApp.status === 'denied') {
            return (
                <>
                    Unfortunately, your application has been <b>DENIED</b> at this time. You will be able to reapply again <b>48 hours</b> after the application was reviewed. The following information was given in response to your denial.
                    <br/><br/>
                    <italic>"{whitelistApp.status_changed_info}"</italic>
                </>
            );
        }

        if (whitelistApp.status === 'approved') {
            if (whitelistApp.status_changed_info !== '') {
                return (
                    <>
                        Congratulations! Your application has been <b>ACCEPTED</b>! You will be able to join the server and start your journey in Legendary Roleplay.
                        Please make sure you fully understand all rules and regulations before joining the server. If you did not automatically receive your role in Discord, please open a ticket!
                        While your application was accepted, the staff member who reviewed your application left the following message.
                        <br/><br/>
                        <italic>"{whitelistApp.status_changed_info}"</italic>
                    </>
                )
            }
            return (
                <>
                    Congratulations! Your application has been <b>ACCEPTED</b>! You will be able to join the server and start your journey in Legendary Roleplay.
                    Please make sure you fully understand all rules and regulations before joining the server. If you did not automatically receive your role in Discord, please open a ticket!
                </>
            )
        }

        return null;
    }

    return (
        <div className='applications'>
            <Application 
                title={'Whitelist Application'}
                description={'This is an application to be able to join Legendary Roleplay. We have this application to help provide a warm and welcoming community for our members where roleplay is the priority! If you are interested in joining, please click apply below!'}
                status={whitelistApp.status || 'Open'}
                statusMessage={getWhitelistStatusMessage()}
                statusChangedDate={whitelistApp.status_changed_date || null}
                path={'/whitelist'}
                buttonText={'Apply Now'}
                userRoles={userRoles}
            />
            <Application 
                title={'Business Applications'}
                description={'Looking to start a business in county? Make sure to put effort into your application as there could be multiple people going for the same business!'}
                status={'Coming Soon'}
                path={'/whitelist'}
                buttonText={'Coming Soon'}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userLoading: state.user.isLoading,
        userDetails: state.user.details,
        userLoggedIn: state.user.isLoggedIn,
        userRoles: state.user.roles
    }
}

export default connect(mapStateToProps)(Applications);


const Application = (props) => {
    const { title, description, status, path, buttonText, statusMessage, statusChangedDate, userRoles } = props;
    const navigate = useNavigate();

    const getStatus = () => {
        if (status === 'Open' && userRoles.includes('Legendary')) {
            return {
                className: 'grandfathered',
                text: 'Grandfathered'
            }
        }

        switch (status.toLowerCase()) {
            case 'open':
                return {
                    className: 'open',
                    text: 'Open'
                };
            case 'coming Soon':
                return {
                    className: 'coming-soon',
                    text: 'Coming Soon'
                };
            case 'pending':
                return {
                    className: 'pending',
                    text: 'Pending'
                };
            case 'review':
                return {
                    className: 'review',
                    text: 'In Review'
                }
            case 'approved':
                return {
                    className: 'accepted',
                    text: 'Accepted'
                }
            case 'denied':
                return {
                    className: 'denied',
                    text: 'Denied'
                }
            default:
                return {
                    className: 'closed',
                    text: 'Closed'
                };
        }
    }

    const displayedStatus = status ? getStatus() : '';

    const renderDeniedApplyButton = () => {
        const daysSinceDenial = moment().diff(moment(statusChangedDate), 'hours');

        if (daysSinceDenial >= 48) {
            return (
                <div className='application-footer'>
                    <div 
                        className='application-footer-button'
                        onClick={() => navigate(`/apps${path}`)}
                    >{buttonText}</div>
                </div>
            )
        } else {
            return (
                <div className='application-footer'>
                    <div className='application-footer-button'>Reapply available in {48 - daysSinceDenial} hour(s)</div>
                </div>
            )
        }
    }

    const renderApprovedApplyButton = () => {
        if (userRoles.includes('Legendary')) return;

        return (
            <div className='application-footer'>
                It looks like you have had your application accepted, but have left the Discord and lost the whitelist role. Please apply again to get your role back.
                <div 
                    className='application-footer-button'
                    onClick={() => navigate(`/apps${path}`)}
                >{buttonText}</div>
            </div>
        )
    }

    return (
        <div className='application'>
            <div className='application-header'>
                <div className='application-header-title'>{title}</div>
                {status && <div className={`application-header-status ${displayedStatus.className}`}>{displayedStatus.text}</div>}
            </div>
            <div className='application-details'>{description}</div>
            {statusMessage && <div className='application-response'>{statusMessage}</div>}
            {status === 'Open' && !userRoles.includes('Legendary') && <div className='application-footer'>
                <div 
                    className='application-footer-button'
                    onClick={() => navigate(`/apps${path}`)}
                >{buttonText}</div>
            </div>}
            {status === 'denied' && renderDeniedApplyButton()}
            {status === 'approved' && renderApprovedApplyButton()}
        </div>
    )
};