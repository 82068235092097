import React from 'react';

const RecipeGenerator = () => {
    return (
        <div className='recipe-generator'>
            Recipe Generator
        </div>
    )
}

export default RecipeGenerator;