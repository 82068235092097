import React, { useState, useEffect } from 'react';
import { useDiscordLogin } from 'react-discord-login';
import '../../styles/components/pages/whitelist.scss';
import whitelist from '../../services/whitelist';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import { errorToast, successfulToast } from '../../helpers/toast-helper';

const Whitelist = (props) => {
    const { userDetails } = props;

    const navigate = useNavigate();
    const { user } = props;

    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfBirthError, setDateOfBirthError] = useState(null);

    const [micConfirm, setMicConfirm] = useState(false);
    const [micConfirmError, setMicConfirmError] = useState(null);

    const [rulesConfirm, setRulesConfirm] = useState(false);
    const [rulesConfirmError, setRulesConfirmError] = useState(null);

    const [roleplayExperience, setRoleplayExperience] = useState('');
    const [roleplayExperienceError, setRoleplayExperienceError] = useState(null);

    const [whyInterested, setWhyInterested] = useState('');
    const [whyInterestedError, setWhyInterestedError] = useState(null);

    const [story, setStory] = useState('');
    const [storyError, setStoryError] = useState(null);

    const [rdm, setRdm] = useState('');
    const [rdmError, setRdmError] = useState(null);

    const [powergaming, setPowergaming] = useState('');
    const [powergamingError, setPowergamingError] = useState(null);

    const [oocBleeding, setOocBleeding] = useState('');
    const [oocBleedingError, setOocBleedingError] = useState(null);

    const [roleplayOverGunplay, setRoleplayOverGunplay] = useState('');
    const [roleplayOverGunplayError, setRoleplayOverGunplayError] = useState(null);

    const [situation, setSituation] = useState('');
    const [situationError, setSituationError] = useState(null);

    const validateValueExists = (value, setError) => {
        if (!value) {
            setError("Required");
            return false;
        } else {
            setError(null);
        }
         
        return true;
    }

    const isValidDate = (dateString) => {
        // Regular expression to match MM/DD/YYYY format
        const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    
        // Check if the format is valid
        if (!datePattern.test(dateString)) {
            return false;
        }
    
        // Parse month, day, and year
        const [month, day, year] = dateString.split("/").map(Number);
    
        // Create a Date object
        const date = new Date(year, month - 1, day); // Months are 0-based in JS
    
        // Check if the date is valid
        return (
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day
        );
    };

    const validateValueIsDate = (value, setError) => {
        if (!isValidDate(value)) {
            setError("Invalid Date");
            return false;
        } else {
            setError(null);
        }

        return true;
    }

    const validation = () => {
        var valid = true;

        valid = validateValueExists(dateOfBirth, setDateOfBirthError);
        valid = validateValueIsDate(dateOfBirth, setDateOfBirthError);

        valid = validateValueExists(micConfirm, setMicConfirmError);
        valid = validateValueExists(rulesConfirm, setRulesConfirmError);
        valid = validateValueExists(roleplayExperience, setRoleplayExperienceError);
        valid = validateValueExists(whyInterested, setWhyInterestedError);
        valid = validateValueExists(story, setStoryError);
        valid = validateValueExists(rdm, setRdmError);
        valid = validateValueExists(powergaming, setPowergamingError);
        valid = validateValueExists(oocBleeding, setOocBleedingError);
        valid = validateValueExists(roleplayOverGunplay, setRoleplayOverGunplayError);
        valid = validateValueExists(situation, setSituationError);

        return valid;
    }

    return (
        <div className='whitelist'>
            <div className='whitelist-header'>
                Whitelist Application
            </div>
            <div className='whitelist-container'>
                <div className='whitelist-container-info'>
                   Please fill out the following questions to the best of your ability. Failure to provide accurate information or providing minimial effort will result in your application being denied.
                   Keep in mind that the use of Artificial Intelligence (AI) to answer these questions is strictly prohibited and will result in an immediate denial. Yes.. we will know if you do.
                </div>
                <TextInput
                    question={'What is your date of birth? (MM/DD/YYYY)'}
                    onChange={setDateOfBirth}
                    value={dateOfBirth}
                    error={dateOfBirthError}
                />
                <CheckBoxInput
                    text={'Do you have a working and quality microphone?'}
                    onChange={setMicConfirm}
                    checked={micConfirm}
                    error={micConfirmError}
                />
                <CheckBoxInput
                    text={'Have you read all the rules regarding Legendary Roleplay?'}
                    onChange={setRulesConfirm}
                    checked={rulesConfirm}
                    error={rulesConfirmError}
                />
                <TextAreaInput
                    question={'Do you have any previous roleplay experience?'}
                    value={roleplayExperience}
                    onChange={setRoleplayExperience}
                    error={roleplayExperienceError}
                />
                <TextAreaInput
                    question={'Why are you interested in playing at Legendary Roleplay?'}
                    value={whyInterested}
                    onChange={setWhyInterested}
                    error={whyInterestedError}
                />
                <TextAreaInput
                    question={'What story have you created to play at Legendary? Go into detail on your character’s name, backstory, and possible arc (Law, Criminal, DOJ, Civilian)'}
                    value={story}
                    onChange={setStory}
                    error={storyError}
                />
                <TextAreaInput
                    question={'In your own words define RDM (Random Death Match)'}
                    value={rdm}
                    onChange={setRdm}
                    error={rdmError}
                />
                <TextAreaInput
                    question={'In your own words define powergaming'}
                    value={powergaming}
                    onChange={setPowergaming}
                    error={powergamingError}
                />
                <TextAreaInput
                    question={'In your own words define what OOC Bleeding is'}
                    value={oocBleeding}
                    onChange={setOocBleeding}
                    error={oocBleedingError}
                />
                <TextAreaInput
                    question={'In your own words define what Roleplay over Gunplay means'}
                    value={roleplayOverGunplay}
                    onChange={setRoleplayOverGunplay}
                    error={roleplayOverGunplayError}
                />
                <TextAreaInput
                    question={'You\'ve been ambushed by the law during a poker game and ended up hogtied. One of your gang members knocks the lawman unconscious but runs off saving themselves and leaving you behind. After two minutes, you hear voices; it\'s the local lawmen searching for you and your gang. If they find you, you\'ll face serious charges. If you break free, you could escape capture. What are you able to do in this situation according to our rules?'}
                    value={situation}
                    onChange={setSituation}
                    error={situationError}
                />
                <div 
                    className='whitelist-submit-button'
                    onClick={async () => {
                        if (!validation()) {

                            errorToast('Please fill out all required fields!');

                            return;
                        }

                        try {
                            await whitelist.addWhitelistApplication({
                                userDetails,
                                dateOfBirth,
                                micConfirm,
                                rulesConfirm,
                                roleplayExperience,
                                whyInterested,
                                story,
                                rdm,
                                powergaming,
                                oocBleeding,
                                roleplayOverGunplay,
                                situation
                            });

                            successfulToast('Successfully submitted application!');

                            navigate('/apps');
                        } catch (err) {
                            errorToast('Failed to submit applicaiton!');
                        }
                    }}
                >
                    Submit Application
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.user.details,
    }
};

export default connect(mapStateToProps)(Whitelist);

const CheckBoxInput = (props) => {

    const { text, onChange, checked, required, error } = props;

    return (
        <div className='checkbox-input'>
            <input onChange={() => {
                onChange(!checked);
            }} checked={checked} type='checkbox' />
            <div className='checkbox-value-container'>
                <div className='checkbox-value'>{text}</div>
                {error && <div className='checkbox-input-error'>{error}</div>}
            </div>
        </div>
    );
}

const TextAreaInput = (props) => {
    const { question, onChange, value, required, error } = props;

    return (
        <div className='textarea-input'>
            <div className='textarea-input-header'>
                <div className='textarea-input-question'>{question}</div>
            </div>
            {error && <div className='textarea-input-error'>{error}</div>}
            <textarea className='textarea-input-value' onChange={(e) => {
                onChange(e.target.value);
            }} value={value} />
        </div>
    )
}

const TextInput = (props) => {
    const { question, onChange, value, required, error } = props;

    const [inputValue, setInputValue] = useState(value || '');

    const handleChange = (e) => {
        let newValue = e.target.value;

        newValue = newValue.replace(/[^0-9/]/g, "");

        setInputValue(newValue);
        onChange(newValue);
    }

    return (
        <div className='text-input'>
            <div className='text-input-header'>
                <div className='text-input-question'>{question}</div>
            </div>
            {error && <div className='text-input-error'>{error}</div>}
            <input className='text-input-value' onChange={handleChange} value={inputValue} maxLength={10} />
        </div>
    )
}