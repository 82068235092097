import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import { Toaster } from 'react-hot-toast';
import Home from './components/pages/Home';
import Developer from './components/pages/Developer';
import Header from './components/header/Header';
import Whitelist from './components/pages/Whitelist';
import Rules from './components/pages/Rules';
import Applications from './components/pages/Applications';
import About from './components/pages/About';
import PortalHeader from './components/header/PortalHeader';
import { connect } from 'react-redux';
import { getUserDetails } from './dux/user-dux';
import LoadingIndicator from './components/indicator/LoadingIndicator';
import PortalSideMenu from './components/portal/sidemenu/PortalSideMenu';
import Dashboard from './components/pages/portal/general/Dashboard';
import ItemsList from './components/pages/portal/general/ItemsList';
import StaffRoster from './components/pages/portal/general/StaffRoster';
import WhitelistApps from './components/pages/portal/general/WhitelistApps';
import RecipeGenerator from './components/pages/portal/developer/RecipeGenerator';
import ManageBusinesses from './components/pages/portal/business/ManageBusinesses';
import WhitelistAppsView from './components/pages/portal/general/WhitelistAppsView';

const LegendaryHome = (props) => {

    const { dispatch, userLoggedIn, userLoading } = props;
    const location = useLocation();

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(getUserDetails());
        }
    }, []);

    if (userLoading) {
        return (
            <div style={{
                backgroundColor: "#313131",
                height: "100%"
            }}>
                <LoadingIndicator
                    label={'Logging In'}
                />
            </div>
        )
    }

    const renderPublicContent = () => {
        return (
            <App>
                <Toaster
                    position='bottom-right'
                    toastOptions={{
                        style: {
                            background: "#313131",
                            color: "#fff"
                        }
                    }}
                />
                <Header/>
                <div className='content-container'>
                    <Routes>
                        <Route
                            path='/'
                            element={<Home/>}
                            exact
                        />
                        <Route
                            path='/developer'
                            element={<Developer/>}
                            exact
                        />
                        <Route
                            path='/rules'
                            element={<Rules/>}
                            exact
                        />
                        <Route
                            path='/about'
                            element={<About/>}
                            exact             
                        />
                        <Route
                            path='/apps'
                            element={<Applications/>}
                            exact             
                        />
                        <Route
                            path='/apps/whitelist'
                            element={<Whitelist/>}
                            exact
                        />
                    </Routes>
                </div>
            </App>
        );
    }

    const renderPortalContent = () => {
        return (
            <App>
                <Toaster
                    position='bottom-right'
                    toastOptions={{
                        style: {
                            background: "#313131",
                            color: "#fff"
                        }
                    }}
                />
                <PortalHeader/>
                <div className='content-container'>
                    <div className='staff-container'>
                        <PortalSideMenu/>
                        <div className='staff-content'>
                            <Routes>
                                <Route
                                    path='/staff'
                                    element={<Dashboard/>}
                                    exact
                                />
                                <Route
                                    path='/staff/roster'
                                    element={<StaffRoster/>}
                                    exact
                                />
                                <Route
                                    path='/staff/items'
                                    element={<ItemsList/>}
                                    exact
                                />
                                <Route
                                    path='/staff/whitelist'
                                    element={<WhitelistApps/>}
                                    exact
                                />
                                <Route
                                    path='/staff/whitelist/:appId'
                                    element={<WhitelistAppsView/>}
                                    exact
                                />
                                <Route
                                    path='/staff/development/recipes'
                                    element={<RecipeGenerator/>}
                                    exact
                                />
                                <Route
                                    path='/staff/business/list'
                                    element={<ManageBusinesses/>}
                                    exact
                                />
                            </Routes>
                        </div>
                    </div>
                </div>
            </App>
        )
    }

    return location.pathname.includes('/staff') ? renderPortalContent() : renderPublicContent()
};

const mapStateToProps = (state) => {
    return {
        userLoading: state.user.isLoading,
        userLoggedIn: state.user.isLoggedIn,
    }
}

export default connect(mapStateToProps)(LegendaryHome);