const discordRoles = {
    '1141978981005013132': 'Owner',
    '1190142458730913944': 'Founder',
    '1139609447501668491': 'Management',
    '1139609447501668489': 'Community Manager',
    '1201798619955466271': 'Admin',
    '1237094350203260989': 'Senior Moderator',
    '1139609447501668484': 'Moderator',
    '1194166310326784040': 'Trial Moderator',
    '1139609447501668490': 'Lead Dev',
    '1139609447501668482': 'Staff',
    '1205857472661946419': 'Tickets',
    '1139609447501668488': 'Developer',
    '1139609447501668487': 'Developer Assistant',
    '1190013884686418041': 'Development Team',
    '1253362562595029013': 'Gang Team',
    '1184985369050087498': 'Report Team',
    '1243404498743853217': 'Support Team',
    '1190004557581914122': 'Business Team',
    '1139609447480688741': 'Graphics Team',
    '1204353521114423316': 'Social Media Team',
    '1204353708604002304': 'Events Team',
    '1152349906682523648': 'Mapper',
    '1141850404922081330': 'Legendary'
}

const getDiscordRole = (roleId) => {
    return discordRoles[roleId];
}

export const getDiscordRoles = (roles) => {
    return roles.map(role => getDiscordRole(role)).filter(role => role);
}

export const isStaff = (roles) => {
    return roles.includes('Staff');
}

export const isAdmin = (roles) => {
    return roles.includes('Admin') || isManagement(roles);
}

export const isManagement = (roles) => {
    return roles.includes('Management') || roles.includes('Founder') || roles.includes('Owner');
}

export const isDeveloper = (roles) => {
    return roles.includes('Development Team');
}

export const isBusinessTeam = (roles) => {
    return roles.includes('Business Team') || isManagement(roles);
}