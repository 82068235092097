import React from 'react';

const Dashboard = () => {
    return (
        <div className='dashboard'>
            Dashboard
        </div>
    )
}

export default Dashboard;