import React from 'react';
import { Provider } from 'react-redux';
import { store } from "./stores/store";
import { setupAxios } from './helpers/axios-setup';
import './styles/main.scss';
import LegendaryHome from './LegendaryHome';
import { BrowserRouter } from 'react-router-dom';

const Root = () => {
    setupAxios();

    return (
        <Provider store={store}>
            <BrowserRouter>
                <LegendaryHome/>
            </BrowserRouter>
        </Provider>
    )
};

export default Root;