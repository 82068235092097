import React from 'react';
import LegendaryLogo from '../../images/legendary_logo_circular.png';
import '../../styles/components/pages/home.scss';

const Home = (props) => {
    return (
        <div className='home'>
            <div className='home-coming-soon'>Coming Soon</div>
            <img src={LegendaryLogo} height={300} width={300}/>
            <div className='home-description'>
                Welcome to Legendary Roleplay's website. We are hard at work to get something up and running,
                but in the meantime you can find all our info on our Discord server by clicking the button below!
            </div>
            <div 
                className='home-discord-button'
                onClick={() => {
                    window.open('https://discord.gg/legendaryrp', '_blank');
                }}
            >
                Join our Discord
            </div>
        </div>
    );
};

export default Home;