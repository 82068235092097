export const getDisplayStatus = (status) => {
    switch (status.toLowerCase()) {
        case 'pending':
            return 'Submitted';
        case 'approved':
            return 'Approved';
        case 'denied':
            return 'Denied';
        case 'review':
            return 'Claimed';
        default:
            return 'Unknown';
    }
}