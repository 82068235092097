import React from 'react';

const About = () => {
    return (
        <div className='about'>
            About
        </div>
    )
}

export default About;