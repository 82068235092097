import React from 'react';
import { Bars } from "react-loader-spinner";
import '../../styles/components/indicator/loading-indicator.scss';

const LoadingIndicator = (props) => {
    return (
        <div className='loading-indicator'>
            <Bars
                color={"#fff"}
                height={props.height || 100}
                width={props.width || 100}
            />
            {props.label && <div className='loading-label'>{props.label}</div>}
      </div>
    );
};

export default LoadingIndicator;