import React from 'react';
import { MdSearch, MdClear, MdAddCircleOutline, MdRefresh } from 'react-icons/md';
import '../../styles/components/inputs/search-bar.scss';

const SearchBar = (props) => {
    return (
        <div className='search-bar'>
            <MdSearch
                onClick={() => {
                    if (props.onSearch) {
                        props.onSearch();
                    }
                }}
                size={17}
                style={{ fill: 'white', marginLeft: '10px', cursor: 'pointer', zIndex: 9998 }}/>
            <input
                autoFocus={props.autoFocus}
                type='text' 
                className='search-bar-value'
                placeholder={props.placeholder}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        if (props.onEnter) {
                            props.onEnter(e.target.value);
                        }
                    }  
                }}
                onChange={(e) => {
                    props.onChange(e.target.value)

                    if (props.extraOnChange) {
                        props.extraOnChange();
                    }
                }}
                value={props.value}
            />
            <MdClear
                onClick={() => {
                    props.onChange('');

                    if (props.onClear) {
                        props.onClear();
                    }
                }}
                size={17} 
                style={{ fill: 'white', marginRight: '10px', cursor: 'pointer' }}
            />
        </div>
    );
};

export default SearchBar;