import React, { useEffect } from 'react';
import LegendaryText from '../../images/legendary_text.png';
import { useDiscordLogin } from 'react-discord-login';
import '../../styles/components/header/header.scss';
import { useLocation, useNavigate } from 'react-router';
import { logoutUser, setUserDetails } from '../../dux/user-dux';
import { connect } from 'react-redux';
import { isDeveloper, isStaff } from '../../helpers/roles-helper';

const Header = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { userDetails, userLoggedIn, userRoles, setPageName, dispatch } = props;

    const discordLoginParams = {
        clientId: '1247700631809036401',
        redirectUri: 'http://legendaryroleplay.com',
        responseType: 'token',
        scopes: ['identify', 'email', 'guilds.members.read'],
        onSuccess: async response => {
            dispatch(setUserDetails(response));
        },
        onFailure: error => {
            console.error('Login failed:', error);
        },
    };

    const { buildUrl, isLoading } = useDiscordLogin(discordLoginParams);

    const displayLoginButton = () => {
        return (
            <div className='login-section-button' onClick={() => (window.location.href = buildUrl())} disabled={isLoading}>
                Sign In
            </div>
        );
    };

    const displayUserDetails = () => {
        const avatar = `https://cdn.discordapp.com/avatars/${userDetails.id}/${userDetails.avatar}.png`;
        return (
            <div className='login-details'>
                <div className='user-details'>
                    <div className='user-welcome'>Welcome,</div>
                    <div className='user-name'>{userDetails.username}</div>
                    <div className='user-logout' onClick={() => {
                        dispatch(logoutUser())
                    }}>Logout</div>
                </div>
                <img className='user-avatar' src={avatar}/>
            </div>
        );
    };

    const checkIfSelected = (path) => {
        if (path === '/') {
            return location.pathname === path ? 'header-item selected' : 'header-item';
        }
        return location.pathname.includes(path) ? 'header-item selected' : 'header-item';
    }

    const navigateToPage = (pageName) => {
        navigate(pageName);
    }

    return (
        <div className='header'>
            <div className='logo-header'>
                <img src={LegendaryText} height={80} width={260} />
            </div>
            <div className='left-header'>
                <div className={checkIfSelected('/')} onClick={() => navigateToPage('/')}>Home</div>
                <div className={checkIfSelected('/rules')} onClick={() => navigateToPage('/rules')}>Rules</div>
                <div className={checkIfSelected('/about')} onClick={() => navigateToPage('/about')}>Info</div>
                <div className={checkIfSelected('/apps')} onClick={() => navigateToPage('/apps')}>Applications</div>
                {(isStaff(userRoles) || isDeveloper(userRoles)) && <div className={checkIfSelected('/staff')} onClick={() => navigateToPage('/staff')}>Staff Portal</div>}
            </div>
            <div className='login-section'>
                {userLoggedIn ? displayUserDetails() : displayLoginButton()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.user.details,
        userLoggedIn: state.user.isLoggedIn,
        userLoading: state.user.loading,
        userRoles: state.user.roles
    }
}

export default connect(mapStateToProps)(Header);