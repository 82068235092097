import React from 'react';

const StaffRoster = () => {
    return (
        <div className='staff-roster'>
            Staff Roster
        </div>
    )
}

export default StaffRoster;