import moment from 'moment';

export function getLocalDateString(date) {
    const utcDate = moment.utc(date);
    const localDate = moment(utcDate).local();

    return localDate.format('ll');
}

export function getLocalDateTimeString(date) {
    const utcDate = moment.utc(date);
    const localDate = moment(utcDate).local();

    return localDate.format('lll');
}